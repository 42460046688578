<template>
    <div class="ui-page">
        <div v-if="!loading && !dataList.length" class="empty-data">暂无数据</div>
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
                v-model="loading"
                :finished="finished"
                @load="getList"
            >
                <van-cell v-for="item of dataList" :key="item.hotelBranchId">
                    <div class="hotel-card" @click="linkRoomList(item.hotelBranchId)">
                        <img class="hotel-card__img" :src="item.branchPic">
                        <div class="hotel-card__bd">
                            <div>{{ item.branchName }}</div>
                            <div class="text-light">{{ item.address }}</div>
                        </div>
                    </div>
                </van-cell>
            </van-list>
        </van-pull-refresh>
    </div>
</template>

<script>
export default {
    name: "HotelList",
    data() {
        return {
            refreshing: false,
            loading: false,
            finished: false,
            dataList: [],
            page: 1,
            limit: 5
        }
    },
    methods: {
        getList() {
            this.loading = true
            this.$axios.get('/api/weChatMini/v1/hotel/branch/branchList', {
                params: {
                    page: this.page,
                    limit: this.limit
                }
            }).then(res => {
                this.refreshing = false
                this.loading = false
                this.finished = true
                this.dataList = res.list
            }).catch(err => {
                this.refreshing = false
                this.loading = false
                this.finished = true
                this.$dialog.alert({
                    message: err.message
                })
            })
        },
        onRefresh() {
            this.page = 1
            this.refreshing = true
            this.getList()
        },
        linkRoomList(id) {
            this.$router.push(`/room-list?hotelId=${id}`)
        }
    },
    mounted() {
        if(this.$store.state.userMode !== 2) {
            this.$router.replace('/my-rooms')
        }
    }
}
</script>

<style scoped>

</style>